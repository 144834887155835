@import '~src/styles/theme.scss';
@import '~src/styles/constant.scss';
@import '~src/styles/media.scss';

.container {
  position: fixed;
  display: flex;
  flex-direction: row;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(theme(black), 0.5);
  padding: 64px 0 64px 0;
  z-index: 1005;
  line-height: 1;

  .box {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    z-index: 1005;
    background-color: theme(white);
    margin: 0 120px 0 120px;
    border-radius: 20px;
    overflow: hidden;

    .top-menu {
      display: flex;
      flex-direction: row;
      border-bottom: solid 2px theme(gray-550);
      z-index: 1006;
      .left {
        width: 58%;
        display: flex;
        padding: 12px 0 0 36px;
        flex-direction: row;
        align-items: center;
        height: 86px;
        & > * {
          margin-right: 8px;
        }
      }
      .right {
        display: flex;
        flex-grow: 1;
        align-items: center;
        margin-right: 25px;

        .save {
          margin-left: auto;
          padding: 20px 25px;
          color: theme(primary);
          font-size: 22px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      flex-shrink: 1;
      overflow: hidden;

      .left {
        width: 58%;
      }
      .right {
        background-color: theme(gray-100);
        border-left: solid 1px theme(gray-550);
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        .right-container {
          padding: 43px 55px;
        }
      }
    }
  }
}

.picker-item {
  display: flex;
  svg {
    width: 19px;
    height: 19px;
    margin: 0 9px 0 3px;
  }
}
