@import '~src/styles/theme.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: theme(gray-400), $alpha: 1);
  height: 100%;
  padding: 16px;
}
