.container {
  line-height: 1;
  .header {
    display: flex;
    margin-bottom: 28px;
    .main {
      font-size: 22px;
      font-weight: 600;
      margin-right: 5px;
      color: theme(black);
    }
    .sub {
      font-size: 18px;
      line-height: 22px;
      font-weight: normal;
      color: theme(gray-800);
      display: flex;
      align-items: center;
    }
  }
  .content {
    margin-bottom: 36px;
  }
}
