$color: (
  white: #ffffff,
  gray-100: #fafafa,
  gray-200: #f5f5f5,
  gray-300: #eeeeee,
  gray-400: #e6e6e6,
  gray-500: #dddddd,
  gray-550: #dbdbdb,
  gray-600: #c7c7c7,
  gray-700: #b2b2b2,
  gray-800: #8b8b8b,
  gray-890: #3e3e3e,
  gray-900: #333333,
  black: #000000,
  primary: #faa680,
  required: #ff0000,
  error: #ff0000,
  pallet-00: #ffe8e8,
  pallet-01: #feffed,
  pallet-02: #e5ffd8,
  pallet-03: #e6ebff,
  pallet-04: #efe2ff,
  pallet-05: #efefef,
);

@function theme($key) {
  @if map-has-key($color, $key) {
    @return map-get($color, $key);
  }
  @warn "Unknown `#{$key}` in theme.";
  @return white;
}
