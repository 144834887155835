@import '~src/styles/theme.scss';
@import '~src/styles/utils.scss';

.container {
  &.overseasActive {
    .radio {
      display: none;
    }
    .overseas {
      display: flex;
      flex-direction: column;
    }
  }
  .overseas {
    display: none;
    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 8px;
      .back-button {
        color: theme(gray-400);
        cursor: pointer;
        margin-left: 8px;
        margin-right: 8px;
        &:hover {
          color: theme(gray-600);
        }
      }
      .search {
        width: 100%;
        height: 50px;
        padding: 16px;
        border: 1px solid theme(gray-500);
        border-radius: 20px;
        background-color: theme(white);
        font-size: 20px;
        font-weight: 400;
        &:focus {
          outline: none;
        }
      }
    }
    .country-list {
      width: 100%;
      height: 300px;
      overflow-y: scroll;
      border: 1px solid theme(gray-500);
      border-radius: 5px;
      background-color: theme(white);
      display: flex;
      flex-direction: column;
      padding: 16px;
      .item {
        padding: 16px;
        font-size: 20px;
        width: 100%;
        cursor: pointer;
        &.selected {
          color: theme(primary);
        }
        &:hover {
          background-color: theme(gray-200);
          border-radius: 5px;
        }
      }
    }
  }
}
