@import '~src/styles/theme.scss';
@import '~src/styles/constant.scss';
@import '~src/styles/media.scss';

.container {
  display: flex;
  height: 100%;
  min-height: 720px;
  flex-direction: column;
  background-color: rgba($color: theme(gray-400), $alpha: 1);
  padding: $navigation-header-height 16px 0px 16px;

  @include mediaUnder(medium) {
    margin: 0;
    height: 100%;
    border-radius: 0;
    padding: $navigation-header-height 0px 0px 0px;
  }

  .content {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: $order-content-max-width;
    margin: auto;
    height: 90%;
    border-radius: 20px;
    overflow: hidden;

    @include mediaUnder(medium) {
      margin: 0;
      height: 100%;
      border-radius: 0;
    }
  }
}
