@import '~src/styles/theme.scss';
@import '~src/styles/constant.scss';

.open-button {
  width: 100px;
  cursor: pointer;
}

.container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(theme(black), 0.5);
  padding: 64px 0 64px 0;
  z-index: 1000;
  .box {
    display: flex;
    flex-direction: column;
    z-index: 1000;
    background-color: theme(white);
    width: 100%;
    max-width: 1200px;
    margin: 0 120px;
    border-radius: 5px;
    overflow: hidden;
    .header {
      display: flex;
      width: 100%;
      padding: 24px 32px;
      justify-content: space-between;
      .title {
        font-size: 24px;
      }
      .close {
        cursor: pointer;
        margin-right: 16px;
      }
    }
    .body {
      display: flex;
      width: 100%;
      padding: 0px 32px;

      .acceptance-scroll {
        width: 100%;
        height: 500px;
        background-color: theme(gray-100);
        border: 1px solid theme(gray-700);
        padding: 16px;
        overflow-y: scroll;
      }
    }
    .footer {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 24px 32px;
      .accept {
        font-size: 16px;
        background-color: theme(white);
        border: 1px solid theme(gray-700);
        border-radius: 32px;
        color: theme(gray-700);
        padding: 16px 32px;
        transition: all 0.1s ease;
        cursor: pointer;

        &:focus {
          outline: none;
        }
        &:hover {
          border: 1px solid theme(gray-800);
          color: theme(gray-800);
        }
      }
    }
  }
}
