@import '~src/styles/theme.scss';

.container {
  .tooltips-producer-email {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 12px;
    color: theme(gray-700);
  }

  .image-uploader {
    width: 100%;
    height: 150px;
  }

  .acceptance {
    margin: 8px 0px;
    display: flex;
    flex-direction: column;
  }
}
