@import '~src/styles/theme.scss';

.container {
  display: flex;
  flex-direction: column;
  .box {
    display: flex;
    height: 70px;
    border: solid 1px theme(gray-600);
    border-radius: 7px;
    z-index: 1000;
    svg {
      margin: auto 20px auto 25px;
    }
    .input-box {
      flex-grow: 1;
      margin: 12px 12px 0 0;
      max-width: 620px;
      input {
        height: 46px;
        background-color: #f7f7f7;
        padding: 2px 16px;
        font-size: 21px;
        outline-style: none;
      }
      .results {
        width: 100%;
        height: 100%;
        flex-grow: 1;
        background-color: theme(white);
        min-height: 400px;
        .result {
          display: block;
          font-size: 18px;
          padding: 14px 16px;
          flex-shrink: 0;
          max-width: 680px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          cursor: pointer;
        }
        .empty {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          height: 80px;
        }
      }
      &:not(.folded) {
        &.upside {
          input {
            border-radius: 0 0 7px 7px;
          }
          .results {
            border-bottom: none;
            border-radius: 7px 7px 0 0;
          }  
        }
        &.downside {
          input {
            border-radius: 7px 7px 0 0;
          }
          .results {
            border-top: none;
            border-radius: 0 0 7px 7px;
          }  
        }
        input {
          border: solid 1px theme(primary);
          border-radius: 7px 7px 0 0;
        }
        .results {
          border: solid 1px theme(gray-600);
          border-radius: 0 0 7px 7px;
        }
      }
      &.folded {
        input {
          border: solid 1px theme(gray-600);
          border-radius: 7px;
        }
        .results {
          display: none;
        }
      }
    }
  }
}
