@import '~src/styles/theme.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid theme(gray-550);

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 58px;
    padding: 0 0 0 8px;

    .label {
      display: flex;
      flex-grow: 1;
      height: 100%;
      cursor: pointer;
      align-items: center;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      .edit {
        color: theme(gray-700);
        cursor: pointer;
      }
    }
  }

  .content {
    width: 100%;
    height: auto;
    max-height: 500px;
    transition: padding 0.2s ease, max-height 0.2s ease;
    overflow: hidden;

    &.folded {
      margin: 0px;
      max-height: 0px;
      padding: 0px 32px;
    }
    &:not(.folded) {
      border-top: 1px solid theme(gray-300);
      background-color: theme(gray-100);
      padding: 20px 32px;
    }
  }
}
