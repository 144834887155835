@import '~src/styles/theme.scss';
@import '~src/styles/media.scss';
@import '~src/styles/constant.scss';

.container {
  float: left;
  background-color: rgba($color: theme(gray-200), $alpha: 1);
  margin: 0;

  .header {
    display: flex;
    width: 100%;
    background-color: theme(white);
    border-bottom: 1px solid theme(gray-500);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    transition: all 0.3s ease;

    .row {
      display: flex;
      width: 100%;
      padding: 0 28px;
      flex-direction: column;

      @include mediaOver(medium) {
        flex-direction: row;
      }

      .icon-container {
        min-height: $navigation-header-height;
        display: flex;
        flex: 1;
        justify-content: space-between;
        transition: all 0.3s ease;

        .logo {
          display: flex;
          justify-content: center;
          align-items: center;

          .image {
            display: block;
            width: auto;
            height: 23px;
            transition: all 0.3s ease;
          }
        }

        .menu-bar {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          @include mediaOver(medium) {
            display: none;
          }
        }
      }

      .menu {
        display: none;
        height: auto;

        &.navListActive {
          display: flex;
        }

        @include mediaOver(medium) {
          display: flex;
        }

        .list {
          display: flex;
          height: 100%;
          flex-direction: column;
          margin: 0;

          @include mediaOver(medium) {
            flex-direction: row;
          }

          .item {
            display: flex;
            align-items: center;
            list-style: none;
            align-items: center;

            a {
              color: theme(gray-700);
              text-decoration: none;
              font-size: 16px;
              font-weight: 400;
              padding: 16px 0px;
              transition: color 0.1s linear;
              line-height: 24px;
              -webkit-font-smoothing: antialiased;

              &:hover {
                color: theme(primary);
              }

              @include mediaOver(medium) {
                padding: 0 7px;
              }
            }
          }

          .setting-icon {
            display: none;
            @include mediaOver(medium) {
              display: flex;
              align-items: center;
              list-style: none;
              align-items: center;
              padding: 0 10px;
            }

            &:hover {
              color: theme(primary);
            }
          }
          .setting-text {
            font-size: 16px;
            font-weight: 400;
            color: theme(gray-700);
            display: flex;
            align-items: center;
            list-style: none;
            line-height: 24px;
            -webkit-font-smoothing: antialiased;
            padding: 16px 0;
            transition: color 0.1s linear;
            @include mediaOver(medium) {
              display: none;
            }

            &:hover {
              color: theme(primary);
            }
          }
        }
      }
    }
  }
}
