@import '~src/styles/theme.scss';

.container {
  display: flex;
  flex-grow: 1;
  height: 46px;
  align-items: center;
  background-color: theme(gray-300);
  overflow: hidden;
  border-radius: 20px;
  min-width: 180px;

  .icon {
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    margin: 0 17px 0 20px;
  }
  .value {
    flex-shrink: 1;
    background-color: theme(gray-300);
    border: none;
    border-width: 0;
    box-shadow: none;
    &:focus {
      outline: none !important;
    }
  }
}
