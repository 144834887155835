@import '~src/styles/theme.scss';
@import '~src/styles/constant.scss';
@import '~src/styles/media.scss';

.container {
  display: inline-block;
  line-height: 1.5;

  .tooltip-popup {
    padding-top: 9px;
    transform: translateX(-70%);
    position: absolute;
    z-index: 80;

    &::after {
      content: '';
      position: absolute;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 9px solid theme(gray-900);
      top: 0;
      left: calc(70% + 4.5px);
    }
  }

  .tooltip-container {
    width: 800px;
    background: theme(gray-900);
    border-radius: 3px;
    color: theme(white);
    font-size: 16px;
    padding: 16px;
    .tooltip-item {
      margin-bottom: 16px;
    }
    @include mediaUnder(large) {
      width: 600px;
    }
    @include mediaUnder(medium) {
      width: 400px;
    }
    @include mediaUnder(small) {
      width: 300px;
    }
  }

  .tooltip-icon {
    cursor: pointer;
  }
}
