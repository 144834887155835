@import '~src/styles/theme.scss';

.container {
  margin-left: 28px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 87px;
  justify-content: center;
  align-items: center;
  .cell {
    width: 90px;
    height: 35px;
    margin-bottom: 10px;
    border: 1px solid theme(gray-700);
    cursor: pointer;
  }
  .pallet-00 {
    background-color: theme(pallet-00);
  }
  .pallet-01 {
    background-color: theme(pallet-01);
  }
  .pallet-02 {
    background-color: theme(pallet-02);
  }
  .pallet-03 {
    background-color: theme(pallet-03);
  }
  .pallet-04 {
    background-color: theme(pallet-04);
  }
  .pallet-05 {
    background-color: theme(pallet-05);
  }
}
