@import '~src/styles/theme.scss';
@import '~src/styles/constant.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: theme(gray-400), $alpha: 1);
  padding: $navigation-header-height 0 0 0;
  min-height: 100%;

  .not-found-order {
    display: flex;
    font-size: x-large;
    margin: 16px;
  }

  .receipt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    background-color: theme(white);
    border-radius: 16px;
    padding: 16px 64px;
    margin: 16px;

    .modify {
      margin-bottom: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      .modify-button {
        width: 360px;
        height: 60px;
        cursor: pointer;
        transition: all 0.1s ease;
        border-radius: 20px;
        border: solid 2px theme(gray-600);
        color: theme(gray-600);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;

        &:hover {
          border: solid 2px theme(primary);
          color: theme(primary);
        }
      }
    }
  }
}
