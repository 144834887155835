h1 {
  @media only screen and (max-width: 1300px) {
    font-size: 72px !important;
  }
  @media only screen and (max-width: 690px) {
    font-size: 52px !important;
  }
  @media only screen and (max-width: 520px) {
    font-size: 42px !important;
  }
}
