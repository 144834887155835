@import '~src/styles/theme.scss';

.receipt {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 0px 16px 0px;

  .receipt-table-title {
    font-weight: 600;
    font-size: 22px;
    width: 100%;
    display: flex;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .empty {
    width: 100%;
    height: 32px;
  }

  .image-records {
    display: flex;
    flex-direction: column;
    width: 100%;
    .row {
      display: flex;
      flex-direction: row;
      width: 100%;
      border-bottom: 1px solid theme(gray-500);
    }
    .headers {
      padding: 8px;
      background-color: theme(gray-300);
    }
    .section {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .box-section {
      padding: 32px 0px;
      border-right: 1px solid theme(gray-500);
    }
    .container-section {
      padding: 32px 0px;
    }
  }
}
