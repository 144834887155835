@import '~src/styles/theme.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
  background-color: theme(white);
  border-radius: 4px;
  padding: 32px;
  box-shadow: 0px 0px 5px theme(gray-600);

  .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 32px;

    .icon {
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }
    .text {
      display: flex;
      width: 100%;
      font-size: x-large;
      font-size: 32px;
    }
  }

  .search-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 32px;

    .input {
      width: 100%;
      padding: 0 25px;
      font-size: 16px;
      border: solid 1px theme(gray-700);
      border-right: none;
      max-width: 100%;
      background-color: transparent;
      height: 60px;
      &:focus {
        outline: none;
      }
    }
    .search-button {
      background-color: theme(gray-700);
      color: theme(gray-800);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 60px;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.1s ease;

      &:hover {
        background-color: theme(primary);
        color: theme(white);
      }
    }
  }
}
