@import '~src/styles/theme.scss';

.container {
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
}

.image {
    width: 500px;
    height: 280px;
    @media only screen and (max-width: 690px) {
        width: 380px;
        height: 212px;
    }
    border: 1px solid theme(gray-600);
    border-radius: 7px;
}