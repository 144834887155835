@import '~src/styles/theme.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: theme(gray-600);
  margin: 32px 0px;
}
