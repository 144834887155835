@import '~src/styles/theme.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 32px;

  .line {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 32px;
    line-height: 4rem;

    .token {
      padding: 0px 4px;
      line-height: 4rem;
      text-align: center;
      &.bold {
        font-weight: 600;
      }
    }

    .tooltips {
      margin-left: 8px;
    }
  }
}
