@import './reset.scss';
@import './fonts.scss';
@import './page-transition.scss';

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}

li {
  list-style-type: none;
}

#root,
input,
textarea {
  height: 100%;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 2;
}

* {
  ::-webkit-scrollbar {
    display: none;
  }
  box-sizing: border-box;
}
