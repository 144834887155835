@import '~src/styles/theme.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  .value {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    color: theme(gray-800);
    margin-bottom: 8px;
    &.bold {
      font-weight: 600;
    }
  }
}
