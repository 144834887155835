@import '~src/styles/theme.scss';

.container {
  display: grid;
  width: 100%;

  &:not(.selection-list) {
    min-height: 100%;
  }

  .list {
    grid-area: 1 / 1 / 1 / 1;
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    & > div {
      height: 100%;
    }
    .placeholder-item {
      display: flex;
      width: 100%;
      height: 63px;
      border-radius: 20px;
      height: 63px;
      border: dashed 1px theme(gray-700);
      margin-bottom: 17px;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: theme(gray-700)
    }
  }

  .empty {
    grid-area: 1 / 1 / 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: theme(gray-400);
    pointer-events: none;
  }
}
