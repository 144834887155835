.container {
  display: flex;
  flex-direction: column;

  .color-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .color-picker {
      max-width: 400px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 16px;
    }
  }
}
