@import '~src/styles/theme.scss';
@import '~src/styles/media.scss';
@import '~src/styles/constant.scss';

.container {
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  overflow-y: scroll;

  @include mediaUnder(large) {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1003;
    box-shadow: 0 0px 40px 0 hsla(0, 0%, 80%, 0.8);
  }

  .header {
    display: flex;
    flex-direction: row;
    height: 105px;
    border-bottom: 1px solid theme(gray-550);
    align-items: center;
    .title {
      display: none;
      align-items: center;
      font-size: 22px;
      flex-grow: 1;
      width: 100%;
    }
    .icon {
      cursor: pointer;
      .open {
        filter: invert(54%) sepia(5%) saturate(67%) hue-rotate(107deg) brightness(95%) contrast(89%);
        width: 18px;
        height: 18px;
        cursor: pointer;
        @include mediaUnder(large) {
          width: 20px;
          height: 20px;
          padding-top: 2px;
        }
      }

      .close {
        filter: invert(54%) sepia(5%) saturate(67%) hue-rotate(107deg) brightness(95%) contrast(89%);
        width: 18px;
        height: 18px;
        cursor: pointer;
        @include mediaUnder(large) {
          display: none;
        }
      }
      .close-media {
        display: none;
        @include mediaUnder(large) {
          display: block;
          width: 16px;
          height: 16px;
          filter: invert(54%) sepia(5%) saturate(67%) hue-rotate(107deg) brightness(95%)
            contrast(89%);
          cursor: pointer;
        }
      }
    }
    &.open {
      padding: 0 45px 0 45px;
      .title {
        display: flex;
      }
    }
    &:not(.open) {
      padding: 0 0 0 0;
      border-bottom: none;
      .title {
        display: none;
      }
      justify-content: center;
    }

    @include mediaUnder(large) {
      position: fixed;
      display: flex;
      flex-direction: row;
      bottom: 32px;
      right: 32px;
      background-color: theme(white);
      width: 64px;
      height: 64px;
      border-radius: 50%;
      z-index: 1004;
      box-shadow: 0 0px 10px 0 hsla(0, 0%, 80%, 0.8);

      &.open {
        padding: 0 45px 0 45px;
        background-color: theme(white);
        position: static;
        border-radius: 0;
        width: 100%;
        box-shadow: none;
      }
    }
    @include mediaUnder(small) {
      display: none;
    }
  }

  .information {
    display: none;
    &.open {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    padding: 0 37px 37px 47px;
  }

  &.open {
    width: $order-summary-max-width;
    min-width: $order-summary-max-width;
    background-color: theme(white);
    @include mediaUnder(small) {
      width: 0px;
      min-width: 0px;
    }
  }
  &:not(.open) {
    width: 64px;
    min-width: 64px;
    background-color: theme(gray-100);

    @include mediaUnder(large) {
      width: 0px;
      min-width: 0px;
    }
  }
}
