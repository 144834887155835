@import '~src/styles/theme.scss';

.selectable {
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  .section {
    .title {
      font-size: 11px;
      font-weight: 300;
      color: theme(gray-800);

      &.ewg_and_color {
        margin-bottom: 4px;
      }
    }
    .bigValue {
      min-height: 19px;
      font-size: 16px;
      font-weight: 600;
      color: theme(gray-890);
    }
    .value {
      min-height: 19px;
      font-size: 14px;
      font-weight: normal;
      color: theme(gray-890);
      .hint {
        font-size: 8px;
        .matched {
          font-weight: 800;
          color: theme(gray-890);
        }
        &:not(.matched) {
        }
      }
    }
    &:not(:last-child) {
      margin-bottom: 9px;
    }
  }
}

.color {
  margin-left: 5px;
  div {
    display: inline;
  }
}
