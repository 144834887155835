.item {  
  &:nth-child(0) {
    margin-right: 4px;
  }
  &:nth-child(1) {
    margin-right: 4px;
  }
  &:nth-child(2) {
    margin-right: 3px;
  }
}