@import '~src/styles/theme.scss';

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 800px;
  margin: 0px auto;

  .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: x-large;
    padding-top: 48px;
    padding-bottom: 16px;
  }

  .order-error {
    display: flex;
    margin: 16px;
    color: theme(error);
  }

  .order {
    margin-bottom: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    .order-button {
      width: 360px;
      height: 60px;
      cursor: pointer;
      transition: all 0.1s ease;
      border-radius: 20px;
      border: solid 2px theme(gray-600);
      color: theme(gray-600);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;

      &:hover {
        border: solid 2px theme(primary);
        color: theme(primary);
      }
    }
  }
}
