@import '~src/styles/theme.scss';

.container {
  .text {
    cursor: pointer;
  }
  .icon {
    color: theme(gray-600) !important;
    transition: color 0.1s linear;
    cursor: pointer;
    &:hover {
      color: theme(primary) !important;
    }
  }

  .menu {
    padding: 8px !important;
    min-width: 180px !important;

    .menu-header {
      color: theme(black);
      font-size: 16px;
      padding: 8px;
      line-height: 24px;
      -webkit-font-smoothing: antialiased;
    }

    .menu-item {
      color: theme(gray-900);
      font-size: 16px;
      padding: 8px 8px 8px 16px;
      transition: color 0.1s linear;
      border-radius: 5px;
      line-height: 24px;
      -webkit-font-smoothing: antialiased;
      cursor: pointer;
      &:hover {
        color: theme(black);
        background-color: theme(gray-200);
      }
    }
  }
}
