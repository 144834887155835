@import '~src/styles/theme.scss';

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 5px;
  justify-content: space-between;

  .selectbox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 54px;
    border: 1px solid theme(gray-700);
    z-index: 1;

    label {
      position: absolute;
      top: 16px;
      left: 18px;
      font-size: 16px;
      z-index: -1;
    }

    select {
      width: 100%;
      height: auto;
      line-height: normal;
      padding: 0.8em 0.5em;
      border: 0;
      opacity: 0;
      appearance: none;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: 15px;
      width: 0;
      height: 0;
      margin-top: -1px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid theme(gray-700);
    }
  }
}
