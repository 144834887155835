@import '~src/styles/theme.scss';

.label {
  display: block;
  color: theme(gray-800);
  width: 100%;
  margin-bottom: 5px;

  .required {
    color: theme(required);
    align-items: center;
    width: 100%;
    margin-top: 8px;
    font-size: large;
  }
}
