@import '~src/styles/theme.scss';

.container {
  .select-button {
    width: 100%;
    padding: 16px;
    margin-bottom: 16px;
    background-color: theme(gray-400);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    transition: all 0.1s ease;
    font-size: 16px;

    &:hover {
      background-color: theme(gray-500);
    }
  }
}
