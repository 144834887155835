@import '~src/styles/theme.scss';
@import '~src/styles/utils.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: theme(white);
  border: solid 1px theme(gray-600);
  border-radius: 8px;
  transition: color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
  width: 250px;
  height: 330px;
  padding: 16px;
  margin: 12px;
  cursor: pointer;
  position: relative;
  color: theme(gray-700);

  &:hover,
  &.selected {
    transform: scale3d(1.02, 1.02, 1.02);
  }

  &.selected {
    color: theme(primary);
    border: solid 2px theme(primary);
    box-shadow: 0px 0px 5px theme(gray-500);
    .icon > svg {
      stroke: theme(primary);
    }
  }

  &.comingSoon {
    background-color: theme(gray-200);
    cursor: not-allowed;
  }

  .image {
    height: 256px;
    margin: 8px;
    fill: black;
  }

  .label {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 48px 0 23px 0;
    width: 100%;
    font-size: 24px;
    text-align: center;
  }

  .comingSoon {
    position: relative;
    top: -90px;
    height: 0px;
    margin: 0px auto;
  }

  .icon {
    align-self: center;
    svg {
      stroke: theme(gray-700);

      @include safety-transition(fill 0.3s);
    }
  }
}
