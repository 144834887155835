@import '~src/styles/theme.scss';

.container {
  width: 100%;
  padding: 0 25px;
  font-size: 16px;
  border: solid 1px theme(gray-700);
  border-radius: 7px;
  max-width: 100%;
  background-color: transparent;
  height: 56px;
  line-height: 56px;
  margin-bottom: 5px;
}
