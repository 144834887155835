@import '~src/styles/theme.scss';

.container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: theme(gray-100);

  .scroll-container {
    .step {
      display: flex;
      flex-grow: 1;
      justify-content: center;
    }
  }
}
