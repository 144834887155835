$breakpoints: (
  small: 640px,
  medium: 900px,
  large: 1450px,
);

@mixin mediaOver($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  } @else {
    @warn "No value for breakpoint `#{$breakpoint}`.";
  }
}

@mixin mediaUnder($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  } @else {
    @warn "No value for breakpoint `#{$breakpoint}`.";
  }
}

:export {
  small: 640;
  medium: 900;
  large: 1200;
}
