.container {
  display: grid;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & > * {
    grid-area: 1 / 1 / 1 / 1;
  }

  .indicator {
    user-select: none;
    pointer-events: none;
    height: 30px;
    width: 100%;
    opacity: 0%;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -o-transition: opacity 0.3s;
    transition: opacity 0.3s;
    z-index: 50;
    &.has-more {
      opacity: 1;
    }
    &.up {
      align-self: start;
    }
    &.down {
      align-self: end;
    }
  }

  .content {
    height: 100%;
    overflow: scroll;

    // Hide scrollbar
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
