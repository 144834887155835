@import '~src/styles/theme.scss';

.container {
  height: 46px;
  .box {
    position: relative;
    display: flex;
    flex-direction: column;
    border: solid 1px theme(gray-550);
    border-radius: 20px;
    background-color: theme(white);
    .header {
      display: flex;
      font-size: 14px;
      font-weight: 600;
      align-items: center;
      width: 100%;
      height: 46px;
      cursor: pointer;
      padding: 0 17px 0 25px;
      .title {
        display: flex;
        flex-grow: 1;
        justify-content: center;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      padding-bottom: 8px;
      .border {
        background-color: theme(gray-550);
        height: 1px;
        margin: 0 7px 4px 7px;
      }
      .item {
        display: flex;
        flex-direction: row;
        padding: 4px 15px 4px 8px;
        font-size: 12px;
        color: theme(black);
        cursor: pointer;
        .radio {
          width: 20px;
          height: 20px;
          margin: 0 8px;
          border-radius: 10px;
          &.selected {
            border: solid 6px theme(primary);
          }
          &:not(.selected) {
            border: solid 1px theme(gray-550);
          }
        }
        .checkbox {
          width: 20px;
          height: 20px;
          margin: 0 8px;
          border-radius: 6px;
          &.selected {
            border: solid 1px theme(primary);
            svg {
              filter: invert(85%) sepia(31%) saturate(4744%) hue-rotate(312deg) brightness(116%)
                contrast(96%);
            }
          }
          &:not(.selected) {
            border: solid 1px theme(gray-550);
            svg {
              filter: invert(72%) sepia(94%) saturate(0%) hue-rotate(150deg) brightness(101%)
                contrast(97%);
            }
          }
        }
      }
    }
  }
}
