@import '~src/styles/theme.scss';
@import '~src/styles/utils.scss';
@import '~src/styles/media.scss';

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 24px 0 5px 0;

  .button {
    color: theme(gray-900);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 40px;
    cursor: pointer;
    transition: color 0.1s ease;

    &.prev {
      margin-right: 16px;
      svg {
        margin-right: 8px;
      }

      &.disable {
        color: theme(gray-300);
      }
    }

    &.next {
      margin-left: 16px;
      svg {
        margin-left: 8px;
      }

      &.disable {
        visibility: hidden;
      }
    }

    svg {
      transition: filter 0.1s ease;
    }

    &:not(.disable) {
      svg {
        filter: invert(13%) sepia(0%) saturate(1585%) hue-rotate(265deg) brightness(99%)
          contrast(81%); //#333333
      }
    }
    &.disable {
      svg {
        filter: invert(100%) sepia(56%) saturate(105%) hue-rotate(255deg) brightness(113%)
          contrast(87%); // #eeeeee
      }
    }

    &:hover {
      color: theme(primary);
      svg {
        filter: invert(75%) sepia(43%) saturate(1333%) hue-rotate(311deg) brightness(113%)
          contrast(96%); // #faa680
      }
    }
  }

  .main-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: row;
    margin-top: 20px;
    .navigation {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      flex-direction: column;

      .progress-frame {
        position: relative;
        top: 0;
        width: 375px;
        height: 6px;
        background-color: theme(gray-300);

        .progress {
          position: relative;
          top: 0;
          left: 0;
          height: 6px;
          border-radius: 3px;
          background-color: theme(primary);
          @include safety-transition(width 0.3s ease);

          &.large {
            display: block;
          }
          &.medium {
            display: none;
          }
          &.small {
            display: none;
          }
        }
      }

      li {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        flex-direction: row;
        position: relative;
        top: -15px;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 125px;
          .level {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            color: theme(gray-700);
            font-size: 14px;
            font-weight: 600;
            background-color: theme(gray-300);
            margin-bottom: 10px;
            cursor: pointer;
            @include safety-transition(background-color 0.3s ease);
            @include safety-transition(background-color 0.3s ease);

            svg {
              filter: invert(100%) sepia(0%) saturate(7491%) hue-rotate(319deg) brightness(103%)
                contrast(99%);
            }
          }
          .text {
            color: theme(gray-700);
            text-align: center;
            line-height: 1.5;
          }

          &.selected {
            .level {
              color: theme(white);
              background-color: theme(primary);
            }
          }
        }
      }
    }
  }

  @include mediaUnder(medium) {
    .button {
      width: 100px;

      &.prev {
        margin-left: 16px;
      }

      &.next {
        margin-right: 16px;
      }
    }

    .main-navigation {
      margin-top: 20px;
      .navigation {
        .progress-frame {
          width: 270px;

          .progress {
            &.large {
              display: none;
            }
            &.medium {
              display: block;
            }
            &.small {
              display: none;
            }
          }
        }

        li {
          .item {
            width: 90px;
            .level {
              margin-bottom: 4px;
            }
            .text {
              color: theme(gray-700);
              text-align: center;
            }
          }
        }
      }
    }
  }
  @include mediaUnder(small) {
    .button {
      width: 32px;
      font-size: 0;

      &.prev {
        margin-left: 16px;
        margin-right: 0px;
      }

      &.next {
        margin-left: 0px;
        margin-right: 16px;
      }
    }

    .main-navigation {
      margin-top: 20px;
      .navigation {
        .progress-frame {
          width: 210px;

          .progress {
            &.large {
              display: none;
            }
            &.medium {
              display: none;
            }
            &.small {
              display: block;
            }
          }
        }

        li {
          .item {
            width: 70px;
          }
        }
      }
    }
  }
}
