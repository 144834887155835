@import '~src/styles/theme.scss';

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 32px;

  .title {
    margin-bottom: 24px;
  }

  .error-message {
    display: flex;
    justify-content: center;
    color: theme(error);
    font-weight: 600;
  }
}
