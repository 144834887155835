@import '~src/styles/theme.scss';
@import '~src/styles/constant.scss';
@import '~src/styles/media.scss';

.container {
  position: relative;
  border: 1px solid theme(gray-400);

  .image_filter {
    filter: brightness(0.7);
    width: 100%;
    height: 100%;
    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;

    .icon_container {
      color: theme(gray-700);
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
  }

  .edit {
    display: flex;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: theme(gray-400);
    cursor: pointer;
  }

  .input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}
