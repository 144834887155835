@import '~src/styles/theme.scss';

.receipt-ingredients-record {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid theme(gray-500);

  .record-key {
    padding: 8px 32px;
    background-color: theme(gray-300);
    width: 280px;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .record-value {
    display: flex;
    flex-direction: column;
    padding: 8px 32px;
    flex: 1;

    .record-value-element {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      .hint {
        margin-left: 8px;
        font-size: 8px;
        .matched {
          font-weight: 800;
          color: theme(gray-890);
        }
        &:not(.matched) {
        }
      }
    }

    .description {
      display: flex;
      width: 100%;
      overflow: hidden;
      color: theme(gray-800);
    }

    & > *:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
