@import '~src/styles/theme.scss';
@import '~src/styles/utils.scss';

.container {
  display: flex;
  flex-direction: row;
  height: 65px;
  background-color: theme(white);
  border-bottom: solid 1px theme(gray-550);
  padding: 0 47px;
  align-items: center;
  cursor: pointer;
  @include safety-no-user-select;

  &.selection-list {
    margin-bottom: 17px;
    border-radius: 20px;
    height: 63px;
    padding: 0 17px 0 20px;
    border: solid 1px theme(gray-700);
    background-color: theme(white);
  }

  .priority {
    height: 39px;
    margin-right: 17px;
    font-size: 14px;
    font-weight: normal;
    color: theme(gray-700);
  }

  .mark {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37px;
    height: 37px;
    border: solid 1px theme(gray-550);
    border-radius: 7px;
    margin: 0 13px 0 0;

    svg {
      filter: invert(89%) sepia(0%) saturate(440%) hue-rotate(147deg) brightness(103%) contrast(86%);
    }

    &.selected {
      background-color: theme(primary);

      svg {
        filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(358deg) brightness(105%)
          contrast(101%);
      }
    }
  }

  .title {
    text-overflow: ellipsis;
    font-size: 15px;
    font-weight: normal;
    height: 20px;
    overflow: hidden;
    white-space: nowrap;
  }

  .icon {
    color: theme(gray-500);

    &.selected {
      color: theme(white);
    }
  }

  .ewg,
  .origin {
    padding-top: 4px;
    margin-left: 11px;
    svg {
      width: 30px;
      height: 30px;
    }
  }

  .remove {
    margin: 0 0 20px auto;
    padding: 5px;
    svg {
      width: 10px;
      height: 10px;
      fill: theme(gray-600);
    }
  }
}
