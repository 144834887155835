@import '~src/styles/theme.scss';
@import '~src/styles/utils.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 8px;

  .item {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;

    .icon {
      align-self: center;
      padding-right: 8px;
      svg {
        width: 32px;
        height: 32px;
        stroke: theme(gray-800);
      }
    }

    .label {
      display: flex;
      align-items: center;
      width: 100%;
      color: theme(gray-800);
    }
  }
}
