@import '~src/styles/theme.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  width: 100%;
  border-radius: 7px;
  cursor: pointer;

  .mark {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin: 8px 16px 8px 8px;
    border: 1px solid theme(gray-700);
    border-radius: 7px;

    &.selected {
      background-color: theme(primary);
      border: 1px solid theme(primary);
    }
  }
  .icon {
    color: theme(gray-500);
    display: none;

    &.selected {
      color: theme(white);
      display: flex;
    }
  }
}
