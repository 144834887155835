@import '~src/styles/theme.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: solid 1px theme(gray-600);
  border-radius: 7px;
  padding: 12px;
  width: 100%;
  margin-bottom: 16px;
  cursor: pointer;
  transition: all 0.1s ease;

  .mark {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin: 8px 16px 8px 8px;
    border: 1px solid theme(gray-700);
    border-radius: 7px;
    transition: all 0.1s ease;
  }
  .icon {
    color: theme(gray-500);
    display: none;
    transition: all 0.1s ease;
  }

  .label {
    margin-left: 12px;
    font-size: 20px;
    transition: all 0.1s ease;
  }

  &.checked {
    border: 1px solid theme(primary);

    .mark {
      background-color: theme(primary);
      border: 1px solid theme(primary);
    }

    .icon {
      color: theme(white);
      display: flex;
    }

    .label {
      color: theme(primary);
    }
  }
}
