@import '~src/styles/theme.scss';

.container {
  width: 100%;
  height: 80px;
  resize: none;
  padding: 16px;
  font-size: 16px;
  border: 1px solid theme(gray-700);
  border-radius: 7px;
  max-width: 100%;
  background-color: transparent;
}
