@import '~src/styles/theme.scss';

.container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;

  .title {
    margin-top: 24px;
  }

  .categories {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 32px 0px;
    width: 100%;
  }
}
