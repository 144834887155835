@import '~src/styles/theme.scss';
@import '~src/styles/utils.scss';
@import '~src/styles/media.scss';

.container {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  .row {
    display: flex;
    flex-direction: row;
  }
  .row-320 {
    min-height: 320px;
    margin-bottom: 32px;
  }
  .col {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .col-1 {
    flex: 1;
  }
  .col-2 {
    flex: 1;
  }
  .divider {
    border: 1px solid theme(gray-300);
    margin: 0px 24px;
  }
  .header {
    font-size: 16px;
    margin-bottom: 16px;
  }
  .color-container {
    display: flex;
    flex-direction: row;
  }
  .card-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    justify-content: space-around;
    label {
      width: 100%;
    }

    .card {
      width: 100%;
      height: 270px;
      border-radius: 7px;
      border: solid 1px theme(gray-700);
      background-color: theme(white);
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      transition: all 0.1s ease;
      cursor: pointer;
      .image {
        width: 140px;
        height: 140px;
        object-fit: contain;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .upload-icon {
        width: 64px;
        height: 64px;
      }

      .title {
        font-size: 20px;
        text-align: center;
        margin-top: 32px;
      }
      svg {
        filter: invert(72%) sepia(94%) saturate(0%) hue-rotate(150deg) brightness(101%)
          contrast(97%);
        @include safety-transition(fill 0.3s);
      }
      &.selected {
        border-radius: 7px;
        box-shadow: 0 3px 8px 0 theme(gray-500);
        border: solid 2px theme(primary);
        color: theme(primary);
        font-weight: 600;
        svg {
          filter: invert(85%) sepia(31%) saturate(4744%) hue-rotate(312deg) brightness(116%)
            contrast(96%);
        }
      }
      &:not(:first-child) {
        margin-left: 16px;
      }
    }

    .image-input {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }
  }

  @include mediaUnder(medium) {
    flex-direction: column;
    .row {
      margin-bottom: 32px;
    }
    .row-320 {
      margin-bottom: 0;
    }
    .divider {
      display: none;
    }
    .card-container {
      .card {
        .image {
          width: 160px;
          height: 160px;
          object-fit: contain;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .upload-icon {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
}
